import React, { useEffect } from 'react';
import { Link, navigate } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';

export default () => {
  useEffect(() => {
    navigate('/');
  }, []);
  return null;
};
